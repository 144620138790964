const serverApi = process.env.REACT_APP_API;

const apiEndpoints: any = {
  get_dashboard_header_data:`${serverApi}get_dashboard_header_data/`,
  get_profits:`${serverApi}get_profits/`,
  get_all_positions_data:`${serverApi}get_all_positions_data/`,
  get_user_balance:`${serverApi}get_user_balance/`,
  balance_history:`${serverApi}balance_history/`,
  get_daily_pnl:`${serverApi}get_daily_pnl/`,
  get_balance_pnl:`${serverApi}get_balance_pnl/`,
  get_best_trading_pairs:`${serverApi}get_best_trading_pairs/`
}

export default apiEndpoints;
