import { Flex, Box, Table, Tbody, Td, Text, Stack, Tr, useColorModeValue, Skeleton } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function UplTable(props: { tableData: any }) {
	const { tableData } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	let defaultData = tableData;

	return (
		<Card alignItems='left' flexDirection='column' w='100%' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					uP&L
				</Text>
			</Flex>
			<Box>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
				{
						tableData == null ? <Stack width='100%' px='20px' mt='30px'>
							<Skeleton height='35px' />
							<Skeleton height='35px' />
							<Skeleton height='35px' />
						</Stack> : 
					<Tbody>
						<Tr
							borderBottom='1px'
							borderBottomColor='grey.200'
							borderBottomStyle='dotted'
						>
							<Td
								padding='3'
								fontSize={{ sm: '18px' }}
								fontWeight='800'
								color='black'
								minW={{ sm: '150px', md: '200px', lg: 'auto' }}
							>
								${defaultData && Number(isNaN(defaultData.upnl_total) ? 0 : defaultData.upnl_total ).toFixed(2)}
							</Td>
							<Td
								padding='3'
								fontSize={{ sm: '18px' }}
								fontWeight='800'
								color='black'
								minW={{ sm: '150px', md: '200px', lg: 'auto' }}
							>
								{defaultData && Number(isNaN(defaultData.percentage_total) ? 0 : defaultData.percentage_total ).toFixed(2)}%

							</Td>
							<Td
								padding='3'
								fontSize={{ sm: '18px' }}
								fontWeight='800'
								color='black'
								minW={{ sm: '150px', md: '200px', lg: 'auto' }}
							>
								Total
							</Td>
						</Tr>
						<Tr
							borderBottom='1px'
							borderBottomColor='grey.200'
							borderBottomStyle='dotted'
						>
							<Td
								color='blackAlpha.900'
								padding='3'
								fontSize={{ sm: '18px' }}
								minW={{ sm: '150px', md: '200px', lg: 'auto' }}
							>
								${defaultData && Number(isNaN(defaultData.upnl_long) ? 0 : defaultData.upnl_long ).toFixed(2)}

							</Td>
							<Td
								color='blackAlpha.900'
								padding='3'
								fontSize={{ sm: '18px' }}
								minW={{ sm: '150px', md: '200px', lg: 'auto' }}
							>
								{defaultData && Number(isNaN(defaultData.percentage_long) ? 0 : defaultData.percentage_long ).toFixed(2)}%
							</Td>
							<Td
								color='blackAlpha.900'
								padding='3'
								fontSize={{ sm: '18px' }}
								minW={{ sm: '150px', md: '200px', lg: 'auto' }}
							>
								Long
							</Td>
						</Tr>
						<Tr
							borderBottom='1px'
							borderBottomColor='grey.200'
							borderBottomStyle='dotted'
						>
							<Td
								color='blackAlpha.900'
								padding='3'
								fontSize={{ sm: '18px' }}
								minW={{ sm: '150px', md: '200px', lg: 'auto' }}
							>
								${defaultData && Number(isNaN(defaultData.upnl_short) ? 0 : defaultData.upnl_short ).toFixed(2)}
							</Td>
							<Td
								color='blackAlpha.900'
								padding='3'
								fontSize={{ sm: '18px' }}
								minW={{ sm: '150px', md: '200px', lg: 'auto' }}
							>
								{defaultData && Number(isNaN(defaultData.percentage_short) ? 0 : defaultData.percentage_short ).toFixed(2)}%
							</Td>
							<Td
								color='blackAlpha.900'
								padding='3'
								fontSize={{ sm: '18px' }}
								minW={{ sm: '150px', md: '200px', lg: 'auto' }}
							>
								Short
							</Td>
						</Tr>
					</Tbody>
				}
				</Table>
			</Box>
		</Card>
	);
} 