import { Flex, Box, Table, Stack, Tbody, Td, Text, Tr, Skeleton } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function ProfitTable(props: { tableData: any }) {
	const { tableData } = props;
	let defaultData = tableData;
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Profit
				</Text>
			</Flex>
			<Box>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
					{
						tableData == null ? <Stack width='100%' px='20px' mt='30px'>
							<Skeleton height='35px' />
							<Skeleton height='35px' />
							<Skeleton height='35px' />
							<Skeleton height='35px' />
						</Stack> : <Tbody>
							<Tr
								borderBottom='1px'
								borderBottomColor='black'
								borderBottomStyle='solid'
							>
								<Td
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									${defaultData && Number(isNaN(defaultData.cost_total) ? 0 : defaultData.cost_total).toFixed(2)}
								</Td>
								<Td
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									{defaultData && Number(isNaN(defaultData.percentage_total) ? 0 : defaultData.percentage_total).toFixed(2)}%
								</Td>
								<Td
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									Total
								</Td>
							</Tr>
							<Tr
								borderBottom='1px'
								borderBottomColor='grey.200'
								borderBottomStyle='dotted'
							>
								<Td
									color='blackAlpha.900'
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									${defaultData && Number(isNaN(defaultData.cost_long) ? 0 : defaultData.cost_long).toFixed(2)}
								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									{defaultData && Number(isNaN(defaultData.percentage_long) ? 0 : defaultData.percentage_long).toFixed(2)}%
								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									Long
								</Td>
							</Tr>
							<Tr
								borderBottom='1px'
								borderBottomColor='grey.200'
								borderBottomStyle='dotted'
							>
								<Td
									color='blackAlpha.900'
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									${defaultData && Number(isNaN(defaultData.cost_short) ? 0 : defaultData.cost_short).toFixed(2)}
								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									{defaultData && Number(isNaN(defaultData.percentage_short) ? 0 : defaultData.percentage_short).toFixed(2)}%
								</Td>
								<Td
									color='blackAlpha.900'
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									Short
								</Td>
							</Tr>
							<Tr
								borderBottom='1px'
								borderBottomColor='grey.200'
								borderBottomStyle='dotted'
							>
								<Td
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									${defaultData && Number(isNaN(defaultData.cost_avg) ? 0: defaultData.cost_avg).toFixed(2)}
								</Td>
								<Td
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									{defaultData && Number(isNaN(defaultData.percentage_cost_avg) ? 0 : defaultData.percentage_cost_avg).toFixed(2)}%
								</Td>
								<Td
									padding='3'
									whiteSpace='nowrap'
									fontSize={{ sm: '18px' }}
									fontWeight='800'
									color='black'
									minW={{ sm: '150px', md: '200px', lg: 'auto' }}
								>
									Daily Average
								</Td>
							</Tr>
						</Tbody>
					}

				</Table>
			</Box>
		</Card>
	);
} 