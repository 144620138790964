import React, { useEffect } from 'react';
import { Box, Icon, SimpleGrid, useColorModeValue, Grid, Flex, Text } from '@chakra-ui/react';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdOutlineShoppingBag, MdRemoveRedEye, MdPerson, MdOutlineShoppingCart } from 'react-icons/md';
import ProfitTable from 'views/admin/default/components/ProfitTable';
import UplTable from 'views/admin/default/components/UplTable';
import FundsUsdt from 'views/admin/default/components/FundsUsdt';
import DailyBalance from 'views/admin/default/components/DailyBalance';
import BestTradingPair from 'views/admin/default/components/BestTradingPair';
import ActivePositions from 'views/admin/positions/components/ActivePositions';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import fetchTradingData from 'api/trading';
import fetchBalanceHistory from 'api/balanceHistory';
import apiEndpoints from 'api/apiEndpoints';
import fetchDailyData from 'api/dailyData';
import fetchBalanceData from 'api/balanceData';
import fetchAllPositions from 'api/positions';
import axios from 'axios';

export default function UserReports() {
	const [isApiLoaded, setIsApiLoaded] = React.useState(false);
	const [profitData, setProfitData] = React.useState<any | null>(null);
	const [balanceHistory, setBalanceHistory] = React.useState<any | null>(null);
	const [DailyData, setDailyData] = React.useState<any | null>(null);
	const [dailyBalance, setDailyBalance] = React.useState<any | null>(null);
	const [besttradingData, setBestTradingData] = React.useState<any | null>(null);
	const [activePositions, setActivePositions] = React.useState<any | null>(null);
	const [filteredActivePositions, setFilteredActivePositions] = React.useState<any | null>(null);
	const [userBalances, setUserBalanceValue] = React.useState<any | null>(null);
	const [upnlData, setUpnlData] = React.useState<any | null>(null);
	const [dashboardHeader, setDashboardHeader] = React.useState<any | null>(null);
	const [apiStatus, setApiStatus] = React.useState<any | null>(false);
	const urlParams = new URLSearchParams(window.location.search);
	let session_userid: number = 0;
	if (localStorage.getItem('role') === "admin") {
		session_userid = 0;
	} else {
		session_userid = Number(localStorage.getItem('user_id'));
	}
	const userid = urlParams.get('user_id') ? urlParams.get('user_id') : session_userid;
  const serverApi = process.env.REACT_APP_API;
	const getData = async () => {
		try {
			const response = await axios.get(`${serverApi}get_apidetail/?user_id=${userid}`);
			setApiStatus(response.data.status);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		try {

			setIsApiLoaded(false);
			const get_dashboard_header_data_params = {
				user_id: userid
			}
			const upnl = {
				upnl_total: 0,
				upnl_long: 0,
				upnl_short: 0,
				percentage_total: 0,
				percentage_long: 0,
				percentage_short: 0
			}
			const [
					tradingResult,
					ProfitData,
					balanceHistory,
					dailydata,
					balanceData,
					bestTrading,
					activePosition,
					userBalanceData
			] = await Promise.all([
					fetchTradingData(apiEndpoints.get_dashboard_header_data, get_dashboard_header_data_params),
					fetchTradingData(apiEndpoints.get_profits, get_dashboard_header_data_params),
					fetchBalanceHistory(apiEndpoints.balance_history, get_dashboard_header_data_params),
					fetchDailyData(apiEndpoints.get_daily_pnl, get_dashboard_header_data_params),
					fetchBalanceData(apiEndpoints.get_balance_pnl, get_dashboard_header_data_params),
					fetchBalanceData(apiEndpoints.get_best_trading_pairs, get_dashboard_header_data_params),
					fetchAllPositions(apiEndpoints.get_all_positions_data, get_dashboard_header_data_params),
					fetchBalanceData(apiEndpoints.get_user_balance, get_dashboard_header_data_params)
			]);

			setDashboardHeader(tradingResult);
			setBalanceHistory(balanceHistory);
			setDailyData(dailydata);
			setDailyBalance(balanceData.result);
			setBestTradingData(bestTrading.result);
			setActivePositions(activePosition.result);
			// Calcualte Profit
			ProfitData.percentage_total = ProfitData.cost_total/userBalanceData.result.total_balance * 100;
			ProfitData.percentage_long = ProfitData.cost_long/userBalanceData.result.total_balance * 100;
			ProfitData.percentage_short = ProfitData.cost_short/userBalanceData.result.total_balance * 100;
			ProfitData.percentage_cost_avg = ProfitData.cost_avg/userBalanceData.result.total_balance * 100;
			setProfitData(ProfitData);
			// Calculate UPNL
			// value for upnl
			let updatedUpnl = { ...upnl };
			updatedUpnl.upnl_total = 0;
			updatedUpnl.upnl_long = 0;
			updatedUpnl.upnl_short = 0;
			//value for funds usdt
			let updatedUserBalance = {
				total_balance: userBalanceData.result.total_balance,
				balance_used: userBalanceData.result.balance_used,
				balance_free: userBalanceData.result.balance_free,
				balance_long: userBalanceData.result.long_balance,
				balance_short: userBalanceData.result.short_balance,
				percent_total: 100,
				percent_used: 0,
				percent_free: 0,
				percent_long: userBalanceData.result.percentage_long_balance,
				percent_short: userBalanceData.result.percentage_short_balance,
			}
			const filteredPositions = activePosition.result.filter((position:any) => position.info.contracts > 0);

			const firstTenPositions = filteredPositions.slice(0, 10);
			setFilteredActivePositions(firstTenPositions);

			activePosition.result.forEach((position: any) => {
					updatedUpnl.upnl_total += position?.info?.unrealizedPnl;
					if (position['info']['info']['side'] === "Buy") {
							updatedUpnl.upnl_long += position['info']['unrealizedPnl'];
					} else if(position['info']['info']['side'] === "Sell") {
							updatedUpnl.upnl_short += position['info']['unrealizedPnl'];
					}
					if (position.side === "long") {
						updatedUserBalance.balance_long += position['info']['collateral']
					} else if (position.side === "short") {
						updatedUserBalance.balance_short += position['info']['collateral']
					}
				});
			updatedUpnl.percentage_total = tradingResult.total_cost === 0 ? 0 : (updatedUpnl.upnl_total / tradingResult.total_cost) * 100;
			updatedUpnl.percentage_long = tradingResult.total_cost === 0 ? 0 : (updatedUpnl.upnl_long / tradingResult.total_cost) * 100;
			updatedUpnl.percentage_short = tradingResult.total_cost === 0 ? 0 : (updatedUpnl.upnl_short / tradingResult.total_cost) * 100;
			
			setUpnlData(updatedUpnl);
			
			
			//Calculate Funds Distribution USDT
			updatedUserBalance.percent_used = updatedUserBalance.balance_used /updatedUserBalance.total_balance * 100;
			updatedUserBalance.percent_free = updatedUserBalance.balance_free /updatedUserBalance.total_balance * 100;
			// updatedUserBalance.percent_long = updatedUserBalance.balance_long /updatedUserBalance.total_balance * 100;
			// updatedUserBalance.percent_short = updatedUserBalance.balance_short /updatedUserBalance.total_balance * 100;
			setUserBalanceValue(updatedUserBalance);
			setIsApiLoaded(true);
		} catch (error) {
				// Handle errors here
				console.error("Error fetching data:", error);
		}
	};
	useEffect(() => {
		getData();
	}, []);

	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			{apiStatus?
			<Box> 
				{
					(localStorage.getItem('role') === 'admin') ? 
						<SimpleGrid columns={{ base: 1, md: 2, lg: 4, '2xl': 4 }} gap='20px' mb='20px' >
							<MiniStatistics
								isApiLoaded={isApiLoaded}
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdPerson} color={brandColor} />}
									/>
								}
								name='Active Users'
								value={dashboardHeader?.active_users}
							/>
							<MiniStatistics
								isApiLoaded={isApiLoaded}
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdRemoveRedEye} color={brandColor} />}
									/>
								}
								name='Active Exchange'
								value={dashboardHeader?.active_exchanges}
							/>
							<MiniStatistics
								isApiLoaded={isApiLoaded}
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdOutlineShoppingBag} color={brandColor} />}
									/>
								}
								name='Active Positions'
								value={dashboardHeader?.active_positions}
							/>
							<MiniStatistics
								isApiLoaded={isApiLoaded}
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdOutlineShoppingCart} color={brandColor} />}
									/>
								}
								name='Total Balances'
								value={dashboardHeader ? `$${dashboardHeader.total_balance}` : ''}
							/>
						</SimpleGrid> : ''
				}

				<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap='20px' mb='20px'>
					<ProfitTable tableData={profitData} />
					<UplTable tableData={upnlData} />
					<FundsUsdt tableData={userBalances} />
				</SimpleGrid>

				<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
					<TotalSpent tableData={balanceHistory} />
					<WeeklyRevenue tableData={DailyData}/>
				</SimpleGrid>

				<Grid templateColumns={{ base: "1fr", md: "1fr", xl: "8fr 4fr" }} gap='20px' mb='20px'>
					<DailyBalance tableData={dailyBalance} />
					<BestTradingPair tableData={besttradingData} />
				</Grid>

				<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
					<ActivePositions tableData={filteredActivePositions} />
				</SimpleGrid>
			</Box>
			:
			<Text>There is no Api detail or Api is incorrect.</Text>
			}
			 
		</Box>
	);
}
