// Chakra imports
import { Box, Flex } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';
import Logo from 'assets/img/logo/logo.png';


export function SidebarBrand() {
	//   Chakra color mode
	return (
		<Flex alignItems='center' flexDirection='column'>
			<Box display="flex" alignItems="center" mb='20px'>
				<Box as="img" src={Logo} w='25px' h='25px' alt="Trading Machine Logo" mb='4px' mr='8px' />
				<Box
					as="h2"
					fontSize='24px'
					fontWeight='semibold'
				>
					TradingMachine.ai
				</Box>
			</Box>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
