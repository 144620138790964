import { Flex, Box, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function DailyBalance(props: { tableData: any }) {
	let { tableData } = props;
	if (tableData == null) {
		tableData = [{ "balance": 2631.52, "profit": -4.05254789, "total_val": 126.9803159, "created_at": "02/28/2024" }, { "balance": 2599.28, "profit": -47.26876936, "total_val": 1244.44180929, "created_at": "02/27/2024" }, { "balance": 2632.76, "profit": -6.64569656, "total_val": 1306.97947551, "created_at": "02/26/2024" }, { "balance": 2630.5, "profit": 6.06036399, "total_val": 688.44164893, "created_at": "02/25/2024" }, { "balance": 2635.77, "profit": -4.88378689, "total_val": 420.26798951, "created_at": "02/24/2024" }, { "balance": 2631.38, "profit": -4.69365141, "total_val": 500.27397606, "created_at": "02/23/2024" }, { "balance": 2613.29, "profit": -6.7656286, "total_val": 314.13918384, "created_at": "02/22/2024" }, { "balance": 2614.97, "profit": 6.41409578, "total_val": 929.76114619, "created_at": "02/21/2024" }, { "balance": 2611.92, "profit": -18.31131982, "total_val": 1817.37236549, "created_at": "02/20/2024" }, { "balance": 2605.85, "profit": 15.22864075, "total_val": 1745.11147812, "created_at": "02/19/2024" }];
	}
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Daily Balance & P&L
				</Text>
			</Flex>
			<Box>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
					<Thead>
						<Tr>
							<Th fontSize='18px' color='black' textAlign='center'>
								Coin
							</Th>
							<Th fontSize='18px' color='black' textAlign='center'>
								Balance
							</Th>
							<Th fontSize='18px' color='black' textAlign='center'>
								PnL
							</Th>
							<Th fontSize='18px' color='black' textAlign='center'>
								PnL%
							</Th>
							<Th fontSize='18px' color='black' textAlign='center'>
								Date
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{
							tableData && tableData.map((data: any, index: any) => (
								<Tr key={index} backgroundColor={data.profit > 0 ? '#d1e7dd' : '#f8d7da'} color='black'>
									<Td fontSize='lg' textAlign='center'>
										USDT
									</Td>
									<Td fontSize='lg' textAlign='center'>
										{data && data.balance}
									</Td>
									<Td fontSize='lg' textAlign='center'>
										{data && Number(data.profit).toFixed(2)}
									</Td>
									<Td fontSize='lg' textAlign='center'>
										{data && (Number(data.profit)/Number(data.balance)*100).toFixed(2)} %
									</Td>
									<Td fontSize='lg' textAlign='center'>
										{data && data.created_at}
									</Td>
								</Tr>
							))

						}
					</Tbody>
				</Table>
			</Box>
		</Card>
	);
} 