import axios from "axios";
const serverApi = process.env.REACT_APP_API;


export async function login({email, password} :any) {
  const response: any = await axios.post(`${serverApi}signin`,{
    email: email,
    password: password
  })

  if (response.status === 200) {
    const user = response.data.user;
    if (user.email_verified > 0 || user.username == 'info@tradingmachine.ai') {
      setSession({ user });
      return 'success';
    } else {
      setSession({ user: null });
      return 'unverified';
    }
  } else {
    setSession({ user: null });
    return 'failed';
  }
} 

export function logout() {
  setSession({ user: null });
}

const setSession = ({ user }: any) => {
  if (user) {
    if (user.username === "info@tradingmachine.ai") {
      localStorage.setItem('role', 'admin');
    } else {
      localStorage.setItem('role', 'user');
    }
    localStorage.setItem('user_id', user.id);
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    localStorage.removeItem('user');
  }
};