import { useState, ChangeEvent, useEffect } from 'react';
import axios from 'axios';
import {
  Alert,
  Box,
  Button,
  FormControl,
  Stack,
  SimpleGrid,
  Select,
  Flex,
  Textarea,
  AlertIcon
} from '@chakra-ui/react';
import Card from 'components/card/Card';

const PasswordChangeForm = () => {
  const [datas, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [preLoading, setPreLoading] = useState(false);
  const [preLoadingStatus, setPreLoadingStatus] = useState(null);
  const [preLoadingText, setPreLoadingText] = useState('');

  const [ParsedData, setParsedData] = useState(`{
    "id": "1L",
    "exchange": "bybit",
    "password": "5555",
    "action": "buy",
    "market_position": "long",
    "symbol": "BTC/USDT:USDT",
    "qty": "3",
    "tp": 3
  }`);
  const serverApi = process.env.REACT_APP_API;
  useEffect(() => {
    // Define an async function to fetch data
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverApi}getbalancedusers`);
        setData(response.data.result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleSelectionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setUsers(selectedOptions);
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setParsedData(event.target.value);
  }

  const handleSubmit = async () => {
    setPreLoadingText("Manual push is in processing.")
    setPreLoadingStatus("success");
    setPreLoading(true);
    setTimeout(() => setPreLoading(false), 2000);
    let parsedData : any;
    try {
      console.log(ParsedData);
      parsedData = JSON.parse(ParsedData);
      try {
        const response = await axios.post(`${serverApi}manual_push`, {
          "message": {
            "id": parsedData.id,
            "exchange": parsedData.exchange,
            "password": parsedData.password,
            "action": parsedData.action,
            "market_position": parsedData.market_position,
            "symbol": parsedData.symbol,
            "qty": parsedData.qty,
            "tp": parsedData.tp
          },
          "users": users
        });
        if (response.data.status === true) {
          setPreLoadingText("Manual push is finished successfully.")
          setPreLoadingStatus("success");
          setPreLoading(true);
          setTimeout(() => setPreLoading(false), 2000);
        } else {
          setPreLoadingText("Manual push failed.")
          setPreLoadingStatus("error");
          setPreLoading(true);
          setTimeout(() => setPreLoading(false), 2000);
        }
      } catch (error) {
        setPreLoadingText("Manual push failed.")
        setPreLoadingStatus("error");
        setPreLoading(true);
        setTimeout(() => setPreLoading(false), 2000);
        console.error('Error:', error);
      }
    } catch (error) {
      setPreLoadingText("Please keep the push data in JSON format.")
      setPreLoadingStatus("error");
      setPreLoading(true);
      setTimeout(() => setPreLoading(false), 2000);
    }
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        {
            preLoading === true ? 
            <Alert status={preLoadingStatus} position='fixed' top='20px' width='280px' right='20px' zIndex='99'>
            <AlertIcon />
             {preLoadingText}
            </Alert> : ""
        }

      <SimpleGrid columns={{ base: 1, md: 1, lg: 1, '2xl': 1 }} gap='20px' mb='20px'>
        <Card py='20px' px='30px' >
          <Box>
            <form id='pushSection'>
              <Stack spacing={6}>
                <Flex gap='4' flexDirection={{ base: 'column', md: 'row', xl: 'row' }}>
                  <FormControl>
                    <Textarea height="300px" defaultValue='{
                      "id": "1L",
                      "exchange": "bybit",
                      "password": "5555",
                      "action": "buy",
                      "market_position": "long",
                      "symbol": "BTC/USDT:USDT",
                      "qty": "3",
                      "tp": 3
                    }' onChange={(e) => handleChange(e)} />
                  </FormControl>
                  <FormControl>
                    <Select height="300px"
                      value={users}
                      multiple
                      onChange={handleSelectionChange}
                    >
                      {datas.map((data, index) => (
                        <option key={index} value ={data.id}  style={{
                          backgroundColor: users.includes(String(data.id)) ? '#e95320' : 'transparent',
                          color:users.includes(String(data.id)) ? 'white' : 'black',
                        }}>{data.id} : {data.name} ${data.balance} </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
                <Button
                  boxShadow="sm"
                  colorScheme='messenger'
                  _hover={{ boxShadow: 'md' }}
                  width="70px"
                  onClick={(e) => handleSubmit()}
                  _active={{ boxShadow: 'lg' }}
                >
                  Push
                </Button>
              </Stack>
            </form>
          </Box>
        </Card>
      </SimpleGrid>
    </Box >
  );
};

export default PasswordChangeForm;