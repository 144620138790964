import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  SimpleGrid,
  Flex,
  Text,
  Select,
  Spacer
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
import axios from 'axios';


const ApiDeatilsForm = () => {
  const [exchangeValue, setExChangeValue] = useState('');
  const [apiKeyValue, setAPIKEYValue] = useState('');
  const [apisecretKey, setAPISecretKey] = useState('');
  const [passphrase, setPassphrase] = useState('');
  const userRole = localStorage.getItem('role');
  const user_id = userRole == "admin" ? 0 : localStorage.getItem("user_id");
  const serverApi = process.env.REACT_APP_API;
  let apiStatus: boolean = false;
  useEffect(() => {
    // Define an async function to fetch data
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverApi}get_apidetail/?user_id=${user_id}`);
        apiStatus = response.data.status;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const current_ip: any = await axios.get('https://api.ipify.org/?format=json');
      const response: any = await axios.post(`${serverApi}change_apikey`, {
        "user_id": user_id,
        "exchange": exchangeValue,
        "api_key": apiKeyValue,
        "api_secret": apisecretKey,
        "passphrase": passphrase,
        "ip": current_ip.data.ip
      });
      alert(response.data.result)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 1, lg: 1, '2xl': 1 }} gap='20px' mb='20px'>
        <Card py='20px' px='30px' >
          <Flex px='10px' mb="20px" mt='20px' flexDirection='column' justifyContent='space-between' align='left'>
            <Text fontSize='18px' fontWeight='500' lineHeight='100%'>
              API details
            </Text>
            <Text fontSize='18px' fontWeight='300' mt='15px' color='gray' lineHeight='100%'>
              We will store your API secret encrypted, so that you will not see again your secret key...
            </Text>
            <Text fontSize='18px' fontWeight='300' mt='15px' color='gray' lineHeight='100%'>
              Please add our ip to whitelist at your exchange
            </Text>
            <Flex fontSize='17px' fontWeight='300' mt='15px' color='gray' lineHeight='100%'>
              IP : <Text fontSize='16px' mt='2px' ml='5px' fontWeight='300' color='red' lineHeight='100%'>164 . 92 . 252 . 132</Text>
            </Flex>
          </Flex>
          <HSeparator mb='20px' />
          <Box>
            <form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <FormControl>
                  <FormLabel>Exchange</FormLabel>
                  <Select placeholder='--Select Exchange--' onChange={(e) => setExChangeValue(e.target.value)}>
                    <option value='bybit'>ByBit</option>
                    <option value='bingx'>BingX</option>
                    <option value='binance'>Binance</option>
                    <option value="okx">Okx</option>
                  </Select>
                  <Text fontSize='15px' fontWeight='100' mt='15px' color='gray' lineHeight='100%'>Please select exchange.</Text>
                </FormControl>
                <FormControl>
                  <FormLabel>API Key</FormLabel>
                  <Input
                    type="text"
                    placeholder="API Key"
                    onChange={(e) => setAPIKEYValue(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>API Secret</FormLabel>
                  <Input
                    type="text"
                    placeholder="API Secret"
                    onChange={(e) => setAPISecretKey(e.target.value)}
                  />
                </FormControl>
                { exchangeValue === "okx" ? 
                  <FormControl>
                    <FormLabel>Passphrase</FormLabel>
                    <Input
                      type="text"
                      placeholder="Passphrase"
                      onChange={(e) => setPassphrase(e.target.value)}
                    />
                  </FormControl> : null
                }
                <Text fontSize='15px' fontWeight='100' mt='15px' color='gray' lineHeight='100%'>If exist please enter your Passphrase.</Text>
                { apiStatus ? 
                <Flex>
                  <Button
                    type="submit"
                    boxShadow="sm"
                    colorScheme='pink'
                    _hover={{ boxShadow: 'md' }}
                    _active={{ boxShadow: 'lg' }}
                    width="48%"
                  >
                    Delete API details
                  </Button>
                  <Spacer />
                  <Button
                    type="submit"
                    boxShadow="sm"
                    colorScheme='messenger'
                    _hover={{ boxShadow: 'md' }}
                    _active={{ boxShadow: 'lg' }}
                    width="48%"

                  >
                    Save
                  </Button>
                </Flex>
                :
                <Button
                  type="submit"
                  boxShadow="sm"
                  colorScheme='messenger'
                  _hover={{ boxShadow: 'md' }}
                  _active={{ boxShadow: 'lg' }}
                >
                  Save
                </Button>
                }
              </Stack>
            </form>
          </Box>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default ApiDeatilsForm;