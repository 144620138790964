import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    CircularProgress,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";

import Card from 'components/card/Card';
// Assets
import illustration from "assets/img/auth/auth.png";
import axios from "axios";

function ResetPassword() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const email = urlParams.get('email');
    const serverApi = process.env.REACT_APP_API;
    const [verifyStatus, setVerifyStatus] = useState(null);
    useEffect(() => {
        // Define an async function to fetch data
        const fetchData = async () => {
          try {
            const response = await axios.post(`${serverApi}verify-email`, {
                  "code": code,
                  "email": email
            });
            console.log(response)
            setVerifyStatus(response.data.status);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
    }, []);

    const handleRedirect = () => {
        window.location.href = '/';
    }

    const handleResendVerify = async () => {
        try {
            const response = await axios.post(`${serverApi}send-verification-email`, {
                "email": email
            });
            alert(response.data.result);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }
    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            { verifyStatus === null ? <CircularProgress isIndeterminate color='lightblue' /> :
                <Card py='15px' alignItems='center' flexDirection='column' w='100%'>
                    { verifyStatus ? 
                        <Flex
                            maxW={{ base: "100%", md: "max-content" }}
                            w='100%'
                            mx={{ base: "auto", lg: "0px" }}
                            me='auto'
                            h='100%'
                            mb={{ base: "10px", md: "20px" }}
                            px={{ base: "25px", md: "0px" }}
                            mt={{ base: "10px", md: "20px" }}
                            alignItems='center'
                            flexDirection='column'
                        >
                                <Heading fontSize='30px' mb='30px' textAlign='center' w='100%'>
                                    Email Verified!
                                </Heading>
                                <Text textAlign='center' mb='30px'>
                                    Thank you for verifying your email address. <br />Your account setup is now complete!
                                </Text>
                                <Button onClick={handleRedirect}>Go to Dashboard</Button>

                        </Flex>
                        : 
                        <Flex
                            maxW={{ base: "100%", md: "max-content" }}
                            w='100%'
                            mx={{ base: "auto", lg: "0px" }}
                            me='auto'
                            h='100%'
                            mb={{ base: "10px", md: "20px" }}
                            px={{ base: "25px", md: "0px" }}
                            mt={{ base: "10px", md: "20px" }}
                            alignItems='center'
                            flexDirection='column'
                        >
                                <Heading fontSize='30px' mb='30px' textAlign='center' w='100%'>
                                Verification Failed
                                </Heading>
                                <Text textAlign='center' mb='30px'>
                                    Oops! There was an issue verifying your email address.
                                </Text>
                                <Button onClick={handleResendVerify}>Resend Verification Code</Button>
                        </Flex>
                    }   
                </Card>
            }
        </DefaultAuth>
    );
}

export default ResetPassword;
