// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Chart from "react-apexcharts";
import Card from 'components/card/Card';

export default function TotalSpent(props: { tableData: any }) {
	let { tableData = [] } = props;
	if (tableData == null) {
		tableData = {"status":false,"series":[],"xaxis":[]};
	}
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const options = {
		chart: {
			id: 'area',
			height: 350,
			zoom: {
				enabled: false
			}
		},
		dataLabels: {
			enabled: false
		},
		xaxis: {
			categories: tableData && tableData.xaxis,
			tickAmount: 15
		},
		yaxis: {
			opposite: true // Display y-axis ticks to the right
		}
	};

	const series = [{
		name: 'USDT',
		data: tableData && tableData.series
	}];

	return (
		<Card flexDirection='column' w='100%' px='15px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text me='auto' color={textColor} mt='15px' fontSize='xl' fontWeight='700' lineHeight='100%'>
					Balance
				</Text>
			</Flex>
			<Chart
				options={options}
				series={series}
				type="area"
				height="400"
			/>
		</Card>
	);
}
