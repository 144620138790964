import axios from "axios";

const fetchBalanceHistory = async (apiUrl: string, params: Record<string, any> = {}): Promise<any | null> => {
    try {
        const response = await axios.get(`${apiUrl}?user_id=${params.user_id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export default fetchBalanceHistory;